import React, { useState, useEffect } from "react"
import  { Link, navigate } from "gatsby"
import styled from "styled-components"
import ConfirmationForm from "components/form"


import { detectMobileAndTablet, isSSR } from "utils/"
import MinDropzone from "components/minDropzone"
import ProfileCard from "components/searchBox/profileCard"
import { language } from "config"



const StyledWrapper = styled.div`
  position: relative;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justif-content: flex-start;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 23.528rem auto;
    grid-gap: 2px;
  }
`

const StyledControl = styled.div`
  padding: 20px 1rem;

  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 11px;
  }

  .input {
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 4px;
    background: #FFFFFF;
    position: relative;
    outline: none;
    width: 100%;
    padding-left: 15px;
    ::placeholder {
      color: #9CB6D0;
      font-size: 13px;
      line-height: 16px;
    }
  }
`

const Container = React.memo(
  ({
    addProfile,
    removeProfile,
    profile,
    file,
    confirmationModal, 
    showConfirmationModal,
    welcomeModal,
    showWelcomeModal,
    putProfile,
    getProfile,
    tagging,
    asking,
    callBackUrl
  }) => {
    const [windowWidth, setWindowWidth] = useState(0)
    const [renderDrop, setRenderDrop] = useState(false)
  
    useEffect(() => {
      let handleWindowSizeChange
      // if (isSSR) is necessary to prevent error during the gatsby build
      if (!isSSR) {
        handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
        // set initial innerWidth when component mounts
        setWindowWidth(window.innerWidth)
      }
      // Add event listener to update windowWidth in state
      window.addEventListener("resize", handleWindowSizeChange)
      return () => window.removeEventListener("resize", handleWindowSizeChange)
    }, [windowWidth])
  
    
    useEffect(() => {
      window.setTimeout(() => {
        setRenderDrop(true)
      }, 10)
    }, [])

    const confirmWelcome = (value) => {
      if(value) {
        console.log('profile', profile)
        putProfile({profile: profile.payload, action: 'init', callBackUrl: callBackUrl})
      } else {
        showWelcomeModal(false)
        showConfirmationModal(false)

      }
    }


    return (
      <StyledWrapper>
          {!detectMobileAndTablet(windowWidth) && (
            <StyledControl>
              <div className="label">
                Bienvenue {profile?.payload?.info?.first_name || ''} 
              </div>
              {file && file.fileName ? (
                <ProfileCard
                  profile={profile.payload}
                  loaded={profile.s}
                  removeProfile={removeProfile}
                  detectFile={() => {
                    showWelcomeModal(false)
                    showConfirmationModal(false)
                    navigate(callBackUrl);
                  }}
                  confirmationModal={confirmationModal}
                  showConfirmationModal={showConfirmationModal}
                  welcomeModal={welcomeModal}
                  showWelcomeModal={showWelcomeModal}
                  putProfile={putProfile}
                  getProfile={getProfile}
                  tagging={tagging}
                  asking={asking}

                />
              )
              :
              (
                <MinDropzone
                  addProfile={addProfile}
                  profile={profile}
                  detectFile={() => navigate(callBackUrl)}
                />
              )
              }
            </StyledControl>
          )}
        {detectMobileAndTablet(windowWidth) && renderDrop && (
          <>
          {file && file.fileName && profile.payload?.info && (
            <StyledControl>
              <div className="label">
                Bienvenue {profile?.payload?.info?.first_name || ''} 
              </div>
              <ProfileCard
                profile={profile.payload}
                removeProfile={removeProfile}
                confirmationModal={confirmationModal}
                putProfile={putProfile}
                getProfile={getProfile}
                showConfirmationModal={showConfirmationModal}
                welcomeModal={welcomeModal}
                showWelcomeModal={showWelcomeModal}
                tagging={tagging}
              />
            </StyledControl>
          )}
          {!file.hasOwnProperty('fileName') && (
            <StyledControl>
              <div className="label">
                {language.dropzone_button_text}
              </div>
              <MinDropzone
                addProfile={addProfile}
                profile={profile}
              />
            </StyledControl>
           )}
          </>
        )}
        {windowWidth !== 0 && (
            <ConfirmationForm 
              profile={profile.payload}
              removeProfile={removeProfile}
              confirmationModal={confirmationModal}
              putProfile={putProfile}
              getProfile={getProfile}
              showConfirmationModal={showConfirmationModal}
              welcomeModal={welcomeModal}
              showWelcomeModal={showWelcomeModal}
              tagging={tagging}
              asking={asking}
              confirmWelcome={confirmWelcome}
              callBackUrl={callBackUrl}
            />
        )}
        
      </StyledWrapper>
    )
  }
)


export default Container
