import React, { useState, useEffect } from "react"
import  { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { addProfile, removeProfile, getProfile, showConfirmationModal, showWelcomeModal, putProfile } from "store/actions/profile"
import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import { siteTitle } from "config"
import Container from "components/confirmation/container"
import { isSSR } from 'utils'

const ConfirmationPage = React.memo(
  ({
    data,
    addProfile,
    removeProfile,
    profile,
    currentTab,
    file,
    getProfile,
    showConfirmationModal,
    confirmationModal,
    welcomeModal,
    showWelcomeModal,
    putProfile,
    tagging,
    asking,
    callBackUrl
  }) => {
    const { frontmatter } = data.index.edges[0].node
    const { useSplashScreen } = frontmatter
    const globalState = {
      // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
      isIntroDone: useSplashScreen ? false : true,
      // darkMode is initially disabled, a hook inside the Layout component
      // will check the user's preferences and switch to dark mode if needed
      darkMode: true,
    }

    useEffect(() => {
      if(!isSSR) {
        setTimeout(() => {
          window.analytics && window.analytics.page();
         }, 100)
      }
    }, [])

    useEffect(() => {
      if(!callBackUrl) {
        navigate("/jobs")
      }
    })

    let otherTab = ""
    if (file && file.fileName) {
      otherTab = currentTab === 'scoring' ? 'searching' : 'scoring'
    } else {
      otherTab = currentTab === 'created_at' ? 'searching' : 'created_at'
    }
    return (
      <GlobalStateProvider initialState={globalState}>
        <Layout>
          <SEO
            title={`${siteTitle} | Nos offres d'emploi`}
          />
           <Container 
              putProfile={putProfile}
              getProfile={getProfile}
              file={file}
              addProfile={addProfile}
              profile={profile}
              removeProfile={removeProfile}
              confirmationModal={confirmationModal}
              showConfirmationModal={showConfirmationModal}
              welcomeModal={welcomeModal}
              showWelcomeModal={showWelcomeModal}
              tagging={tagging}
              asking={asking}
              callBackUrl={callBackUrl}
            />
        </Layout>
      </GlobalStateProvider>
    )
  }
)

ConfirmationPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  file: state.profile.file,
  confirmationModal: state.profile.confirmationModal,
  welcomeModal: state.profile.welcomeModal,
  tagging: state.profile.tagging,
  asking: state.profile.asking,
  callBackUrl: state.profile.callBackUrl
})

export default connect(mapStateToProps, { addProfile, putProfile, removeProfile, getProfile, showConfirmationModal, showWelcomeModal })(ConfirmationPage)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/index/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    drop: allMdx(filter: { fileAbsolutePath: { regex: "/index/drop/" } }) {
      edges {
        node {
          frontmatter {
            title
            titlePrefix
            subtitle
            subtitlePrefix
          }
        }
      }
    }
  }
`
